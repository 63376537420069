<template>
    <modal
    ref="editarProd"
    :titulo="tipo == 'remplazar' ? '' :producto.nombre"
    :img="tipo == 'remplazar' ? null :producto.imagen"
    :regresar="tipo!=''?true:false"
    :tamano="tipo == 'remplazar' ? 'modal-xl' : ''"
    @regresar="tipo=''"
    @guardar="accion_producto"
    >
        <div class="row mx-0">
            <div class="px-0 text-general" :class="tipo == 'remplazar' ? 'col' : 'col-12'">
                <div v-show="tipo == 'remplazar'" class="row mx-0 justify-center ">
                    <img :src="producto.imagen" class="objet-fit border br-12 p-2 " style="width:111px;height:111px;border: 1px solid #DBDBDB;" />
                </div>
                <div v-show="tipo == 'remplazar'" class="row mx-5 my-2 f-600 f-14">
                    {{ producto.nombre }}
                </div>
                <div class="row mx-5 my-2 f-600 f-14" :class="{'justify-center':tipo != 'remplazar'}">
                    {{ convertMoneyTendero(producto.precio,pedido.idm_moneda) }}
                </div>
                <div class="row mx-5 my-2 mt-3 f-15">
                    <div class="col-10 text-center">
                        {{ producto.descripcion }}
                    </div>
                </div>
                <div class="row mx-5 my-2 f-15">
                    <b class="mr-2">
                        Presentación:
                    </b>
                    <p>{{ producto.presentacion }}</p>
                </div>
                <div class="row mx-5 my-2 f-15">
                    <b class="mr-2">
                        Unidad de medida:
                    </b>
                    <p>{{ producto.unidad }}</p>
                </div>
                <div class="row mx-5 my-2 f-15">
                    <b class="mr-2">
                        Venta mínima:
                    </b>
                    <p>{{ producto.cantidad_minima }} {{ producto.unidad }}</p>
                </div>
                <div class="row mx-5 my-2 f-15 d-middle justify-content-between">
                    <div class="d-middle">
                        <b class="mr-2 text-general">
                            Cantidad Pedida:
                        </b>
                        <p>{{ producto.cantidad_pedida }}</p>
                    </div>
                    <p>Total: {{ producto.total_pedida }}</p>
                </div>
                <div v-show="tipo=='' || tipo == 'cantidad'" class="mt-4">
                    <div class="row justify-center">
                        <div class="col-4 px-0 text-center br-10  mx-2 position-relative">
                            <label class="position-absolute f-12" style="top:-17px; left:41px">Editar Cantidad</label>
                            <el-input-number
                            v-model="model.cantidad"
                            :min="minimo" :step="salto"
                            :step-strictly="entero"
                            :precision="presicion"
                            style="width:160px;"
                            @input="tipo = 'cantidad'"
                            />
                        </div>
                        <el-tooltip placement="bottom" content="Reemplazar" effect="light">
                            <div class="col-auto text-center br-10 bg-light-f5 cr-pointer p-1 px-1 border shadow mx-2" @click="tipo='remplazar'">
                                <i class="icon-swap-horizontal-bold f-20 text-general2" />
                            </div>
                        </el-tooltip>
                        <el-tooltip placement="bottom" content="Eliminar" effect="light">
                            <div class="col-auto text-center br-10 bg-light-f5 cr-pointer p-1 px-1 border shadow mx-2" @click="eliminarProd">
                                <i class="icon-trash f-20 text-general2" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <div v-show="tipo == 'cantidad' || tipo == ''" class="row mx-auto px-3 mt-4">
                    <div class="w-100">
                        <label class="f-13">Comentario</label>
                        <el-input v-model="comentario" type="textarea" :rows="2" show-word-limit maxlength="200" />
                    </div>
                </div>
            </div>
            <div v-show="tipo=='remplazar'" class="col border-left">
                <p class="text-general f-15 text-center">Buscar producto de reemplazo</p>
                <div class="row mx-0 justify-content-center mt-2">
                    <div class="col-8 px-0 text-center">
                        <el-select
                        v-model="model.id_producto_nuevo"
                        size="medium"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="Buscar producto"
                        :remote-method="remoteMethod"
                        :loading="loading"
                        @change="set_minimo"
                        >
                            <el-option
                            v-for="item in opciones"
                            :key="item.id"
                            :label="`${item.sku} - ${item.nombre} - ${item.presentacion}`"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                    <template v-if="model.id_producto_nuevo != null">
                        <div class="col-8 text-center my-2">
                            <img :src="producto_nuevo.imagen" class="objet-fit border br-12" style="width:111px;height:111px;border: 1px solid #DBDBDB;" />
                        </div>
                        <div class="col-8">
                            <p class="text-general f-600 f-17 mt-1">
                                {{ producto_nuevo.nombre }}
                            </p>
                            <p v-if="producto_nuevo.promocion" class="mt-2">
                                <span class="text-general f-600"> {{ convertMoneyTendero(producto_nuevo.data_promocion.promo_valor,pedido.idm_moneda) }}</span>
                                <span class="text-general2 f-600 f-13" style="text-decoration:line-through;">  {{ convertMoneyTendero(producto_nuevo.precio,pedido.idm_moneda) }} </span>
                            </p>
                            <p v-else class="mt-2">
                                <span class="text-general f-600"> {{ convertMoneyTendero(producto_nuevo.precio,pedido.idm_moneda) }}</span>
                            </p>
                            <span v-if="producto_nuevo.promocion" class="bg-general text-white f-14 br-12 p-1 px-2 my-2">
                                {{ producto_nuevo.data_promocion.texto }}
                            </span>
                            <p class="text-general f-15 mt-1">
                                {{ producto_nuevo.descripcion }}
                            </p>
                            <p class="text-general mt-1">
                                <span class="f-15 f-500">
                                    Presentacion:
                                </span>
                                {{ producto_nuevo.presentacion }}
                            </p>
                            <p class="text-general mt-1">
                                <span class="f-15 f-500">
                                    Unidad de medida:
                                </span>
                                {{ producto_nuevo.unidad }}
                            </p>
                            <p class="text-general mt-1">
                                <span class="f-15 f-500">
                                    Venta mínima:
                                </span>
                                {{ agregarSeparadoresNumero(producto_nuevo.cantidad_minima,2) }}
                            </p>
                        </div>
                        <div class="col-xl-8 col-lg-9 border br-12 mt-2">
                            <div class="d-flex mx-0">
                                <div class="col px-0">
                                    <p class="text-left text-general my-1 f-15">
                                        cantidad
                                    </p>
                                    <el-input-number
                                    v-model="model.cantidad_nueva" size="small"
                                    class="mb-2 transparent-input"
                                    :min="minimo_nuevo"
                                    :step="salto_nuevo"
                                    :step-strictly="entero_nuevo"
                                    :precision="presicion_nuevo"
                                    />
                                </div>
                                <div class="col text-general text-right d-flex align-items-end justify-content-end pb-3">
                                    <strong>
                                        Total:
                                    </strong>
                                    {{ convertMoneyTendero(total_nuevo,pedido.idm_moneda) }}
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Pedidos from '~/services/pedidos/pedidos-tienda'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            editar: '',
            tipo: '',

            loading:false,
            minimo_nuevo:0,
            salto_nuevo:0,
            entero_nuevo:false,
            presicion_nuevo:0,
            minimo:0,
            salto:0,
            entero:false,
            presicion:0,
            opciones:[],
            datos:{},
            producto:{nombre:''},
            model:{
                id_producto_nuevo:null,
                cantidad:'',
                cantidad_nueva:0,
            },
            comentario:'',
            producto_nuevo:{data_promocion:{}},

        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos/id_pedido',
            pedido: 'pedidos/pedidos/pedido',
        }),
        total_nuevo(){
            if(this.producto_nuevo.promocion){
                return this.producto_nuevo.data_promocion.promo_valor * this.model.cantidad_nueva
            }else{
                return this.producto_nuevo.precio * this.model.cantidad_nueva
            }
        }
    },
    methods: {
        toggle(){
            this.$refs.editarProd.toggle()
        },
        limpiar(){
            this.tipo = ''
            this.opciones = []
            this.model = {
                id_producto_nuevo:null,
                cantidad:0,
                cantidad_nueva:0,
                comentario:''
            }
            this.minimo_nuevo = 0
            this.salto_nuevo = 0
            this.entero_nuevo = false
            this.presicion_nuevo =0
            this.minimo = 0
            this.salto = 0
            this.entero= false
            this.presicion =0
        },
        datos_basicos(prod){
            console.log(prod);
            this.limpiar()
            this.datos = prod
            let id = prod.producto.id
            this.minimo = parseFloat(prod.producto.cantidad_minima)
            this.salto = parseFloat(prod.producto.cantidad_minima)
            this.entero = prod.producto.cantidad_tipo === 1
            this.presicion = prod.producto.cantidad_tipo === 1 ? 0 : 2
            this.model.cantidad = parseFloat(prod.cantidad)
            this.comentario = prod.estado_comentario
            this.producto = {
                id:prod.producto.id,
                nombre:prod.producto.nombre,
                descripcion:prod.producto.descripcion,
                imagen:prod.producto.imagen,
                precio: prod.unidad_final,
                presentacion:prod.producto.presentacion,
                unidad:prod.producto.unidad,
                cantidad_minima:this.agregarSeparadoresNumero(prod.producto.cantidad_minima,2),
                cantidad_pedida: this.agregarSeparadoresNumero(prod.cantidad,2) + prod.producto.unidad,
                total_pedida: this.convertMoneyTendero(prod.total_final,this.pedido.idm_moneda)
            }
            this.$refs.editarProd.toggle()
        },
        async accion_producto(){
            try {

                if(this.tipo === 'cantidad' || this.tipo === ''){
                    if(this.comentario === '' && this.tipo === '') return
                    let model = {
                        id_producto:this.producto.id,
                        cantidad:this.model.cantidad,
                        comentario:this.comentario
                    }
                    const {data} = await Pedidos.alter_producto(this.datos.id, model)
                    this.notificacion('Registro Actualizado','','success')
                    //recalculamos todos los datos
                    // this.$store.dispatch('pedidos/pedidos/listar_pedidos')
                    this.$store.dispatch('pedidos/pedidos/pedidos_productos', Number(this.id_pedido))
                    this.$store.dispatch('pedidos/pedidos/pedidos_resumenes', Number(this.id_pedido))


                }
                if(this.tipo === 'remplazar'){
                    if(this.model.id_producto_nuevo === null){
                        this.notificacion('Advertencia','Por favor seleccione el producto nuevo','warning')
                        return
                    }
                    let model = {
                        id_producto:this.model.id_producto_nuevo,
                        cantidad:this.model.cantidad_nueva
                    }
                    const {data} = await Pedidos.alter_producto(this.datos.id, model)
                    this.notificacion('Registro Actualizado','Se ha remplazado el producto correctamente','success')
                    //recalculamos todos los datos
                    // this.$store.dispatch('pedidos/pedidos/listar_pedidos')
                    this.$store.dispatch('pedidos/pedidos/pedidos_productos', Number(this.id_pedido))
                    this.$store.dispatch('pedidos/pedidos/pedidos_resumenes', Number(this.id_pedido))
                }

                this.$refs.editarProd.toggle()

            } catch (e){
                this.error_catch(e)
            } finally {

            }
        },
        async remoteMethod(query){
            try {
                if(query.length > 3){

                    this.loading = true

                    let params = {
                        id_pedido:this.id_pedido,
                        query
                    }

                    const {data} = await Pedidos.buscar_productos(params)
                    this.opciones = data.productos
                }

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        set_minimo(id){
            let obj = this.opciones.find(o=>o.id === id)
            this.model.cantidad_nueva = parseFloat(obj.cantidad_minima)
            this.minimo_nuevo = parseFloat(obj.cantidad_minima)
            this.salto_nuevo = parseFloat(obj.cantidad_minima)
            this.entero_nuevo =obj.cantidad_tipo === 1
            this.presicion_nuevo = obj.cantidad_tipo === 1 ? 0 : 2
            this.producto_nuevo = obj
        },
        eliminarProd(){

            this.$refs.editarProd.toggle()
            this.$emit('eliminar',this.datos.id)
        }
    }
}
</script>
<style lang="css" scoped>
.br-12{border-radius: 12px !important;}
</style>
